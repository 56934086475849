import React, { useState } from 'react';
import { graphql } from 'relay-runtime';
import '../../../stylesheets/components/globals.module.scss';
import '../../../stylesheets/components/theme.module.scss';
import '../../../stylesheets/components/buttonGlobals.module.scss';
import '../../../stylesheets/components/dropdownMenus.module.scss';
import '../../../stylesheets/components/dialog.module.scss';
import Text, { TextFieldGlimmer } from '../tenant_portal/components/Text';
import PortalButton from '../components/PortalButton';
import {
  PreloadedQuery,
  useMutation,
  usePreloadedQuery,
  useQueryLoader,
} from 'react-relay';
import { ChangePasswordLoginMutation$data } from './__generated__/ChangePasswordLoginMutation.graphql';
import { ErrorType } from '../components/Types';
import PageHeader, { PageHeaderGlimmer } from '../tenant_portal/PageHeader';
import SubmitSpinner from '../components/SubmitSpinner';
import { updateDeep } from '../components/fields/FieldTools';
import { FieldsDeepUpdate } from '../typescript/types/FieldComponentProps';
import { useLoaderData, useNavigate, useParams } from 'react-router-dom';
import type {
  ChangePasswordQuery as ChangePasswordQueryType,
  ChangePasswordQuery$data as ChangePasswordQueryResponse,
} from './__generated__/ChangePasswordQuery.graphql';
import SuccessPage from '../tenant_portal/SuccessPage';
import { PortalButtonGlimmer } from '../components/PortalGlimmerElements';
import { formatErrorMessages, filterErrorsByPath } from '../components/Tools';
import Alert from '../components/Alert';

export const ChangePasswordQuery = graphql`
  query ChangePasswordQuery($token: String!) {
    login(passwordResetToken: $token) {
      id
    }
  }
`;

const LoginChangePasswordMutation = graphql`
  mutation ChangePasswordLoginMutation($input: LoginChangePasswordInput!) {
    loginChangePassword(input: $input) {
      errors {
        path
        messages
      }
    }
  }
`;

const ChangePassword = () => {
  const { resetPasswordToken } = useParams();
  const initialData = {
    password: '',
    passwordConfirmation: '',
  };
  const [resetPasswordFields, setResetPasswordFields] = useState(initialData);
  const [isPasswordReset, setIsPasswordReset] = useState(false);
  const navigate = useNavigate();
  const [errors, setErrors] = useState<ErrorType[]>([]);

  const baseErrors = filterErrorsByPath(errors);
  const formattedBaseErrorMessages = formatErrorMessages(baseErrors, '');

  const loaderQueryReference = useLoaderData() as PreloadedQuery<
    ChangePasswordQueryType,
    ChangePasswordQueryResponse
  >;

  const [queryReference] = useQueryLoader<ChangePasswordQueryType>(
    ChangePasswordQuery,
    loaderQueryReference
  );

  const loginActivationData = usePreloadedQuery<ChangePasswordQueryType>(
    ChangePasswordQuery,
    queryReference as PreloadedQuery<
      ChangePasswordQueryType,
      ChangePasswordQueryResponse
    >
  );

  const [commitMutation, isMutationInFlight] = useMutation(
    LoginChangePasswordMutation
  );

  const login = loginActivationData.login;
  if (!login) {
    return <PageHeader title="Invalid or expired activation token" />;
  }

  const handleResetPasswordFieldChange: FieldsDeepUpdate = (
    fieldName,
    value
  ) => {
    setResetPasswordFields((prevFields) => {
      return updateDeep(prevFields, fieldName, value);
    });
  };

  const onEnterDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') handleSubmit();
  };

  const handleSubmit = () => {
    const blankErrors: ErrorType[] = [];
    Object.entries(resetPasswordFields).forEach(([key, value]) => {
      if (value === '') {
        blankErrors.push({
          path: key,
          messages: ["can't be blank"],
        });
      }
    });

    if (blankErrors.length > 0) {
      setErrors(blankErrors);
      return;
    }

    commitMutation({
      variables: {
        input: {
          login: {
            password: resetPasswordFields.password,
            passwordConfirmation: resetPasswordFields.passwordConfirmation,
            resetPasswordToken: resetPasswordToken,
          },
        },
      },
      onCompleted: (response) => {
        const responseData = response as ChangePasswordLoginMutation$data;
        console.log('completed! response: ', responseData);
        if (!responseData || !responseData.loginChangePassword) {
          setErrors([
            {
              path: 'base',
              messages: [
                'There was an error. Please contact Property Matrix support for assistance.',
              ],
            },
          ]);
          return;
        }
        if (
          responseData.loginChangePassword.errors &&
          responseData.loginChangePassword.errors.length > 0
        ) {
          setErrors(responseData.loginChangePassword.errors as ErrorType[]);
          return;
        }
        setIsPasswordReset(true);
      },
      onError: (error) => {
        setErrors([
          {
            path: 'base',
            messages: [
              'There was an error. Please contact Property Matrix support for assistance.',
            ],
          },
        ]);
        console.error(error);
      },
    });
  };

  if (isMutationInFlight)
    return <SubmitSpinner spinnerText={'Resetting password...'} />;

  if (isPasswordReset) {
    return (
      <SuccessPage
        successText={'Password reset'}
        successSubText={'Your password has been reset. Please log back in.'}
      >
        <PortalButton onClick={() => navigate('../email')}>
          Back to Login
        </PortalButton>
      </SuccessPage>
    );
  }
  return (
    <>
      {baseErrors.length > 0 && (
        <Alert severity="error">{formattedBaseErrorMessages}</Alert>
      )}
      <PageHeader title="Reset password" />
      <Text
        field={{
          label: 'Password',
          name: 'password',
          type: 'password',
          value: resetPasswordFields.password,
          autocomplete: 'password',
        }}
        mode="edit"
        onChange={handleResetPasswordFieldChange}
        onKeyDown={onEnterDown}
        errors={filterErrorsByPath(errors, ['password'])}
      />
      <Text
        field={{
          label: 'Confirm Password',
          name: 'passwordConfirmation',
          type: 'password',
          value: resetPasswordFields.passwordConfirmation,
          autocomplete: 'password',
        }}
        mode="edit"
        onChange={handleResetPasswordFieldChange}
        onKeyDown={onEnterDown}
        errors={filterErrorsByPath(errors, ['passwordConfirmation'])}
      />
      <PortalButton onClick={handleSubmit}>Reset password</PortalButton>
    </>
  );
};

export const ChangePasswordGlimmer = () => {
  return (
    <>
      <PageHeaderGlimmer />
      <TextFieldGlimmer />
      <TextFieldGlimmer />
      <PortalButtonGlimmer />
    </>
  );
};

export default ChangePassword;
